import Header from "../components/Headers/ConnectionHeader.js";
import React, { useState, useEffect } from "react"
import axios from 'axios';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Collapse,
} from "reactstrap";
import { logThisEvent,events } from "helpers/Analytics";
import { audit_screen } from "../content.js";
import {  toast } from "react-toastify";
import moment from "moment";
import AuditRequestServices from 'services/AuditRequests.js';
import { Spinner } from "reactstrap"
import PermissionsGate from "../components/Permissions/PermissionsGate.js";
import PERMISSIONS from "../components/Permissions/Permissions.js";
import { TbRefresh } from "react-icons/tb";

const apiURL = process.env.REACT_APP_API_URL + "/api";

const Audit = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [requests, setRequests] = useState([]);

    const [conflictRequests, setConflictRequests] = useState([]);
    const [_requests, _setRequests] = useState([]);

    const [prevRequests, setPrevRequests] = useState([]);
    const [prevConflictRequests, setPrevConflictRequests] = useState([]);

    const [reversalModalIsOpen, setReversalModalIsOpen] = useState(false);
    const [reversalModalData, setReversalModalData] = useState({});

    const [rejectionModalIsOpen, setRejectionModalIsOpen] = useState(false);
    const [rejectionModalData, setRejectionModalData] = useState({});

    const [modalViewDetailIsOpen, setModalViewDetailIsOpen] = useState(false);
    const [veiwDetailData, setVeiwDetailData] = useState({});
    const [getReqLoading, setGetReqLoading] = useState(false);
    const [pendingCount, setPendingCount] = useState(0);
    const [conflictsCount, setConflictsCount] = useState(0);
    const [filter, setFilter] = useState(
        {}
    );
    const [filterCollapse, setFilterCollapse] = useState(true);
    const [reversalReason, setReversalReason] = useState("");
    const [rejectionReason, setRejectionReason] = useState("");
    const [auditLogs, setAuditLogs] = useState([]);

    const viewDetail = (data) => {
        setModalViewDetailIsOpen(true);
        getAuditLogs(data._id);
        setVeiwDetailData(data)
    }

    const reversalConfirmation = (data) => {
        setReversalModalIsOpen(true);
        setReversalModalData(data)
    }

    const rejectionConfirmation = (data) => {
        setRejectionModalIsOpen(true);
        setRejectionModalData(data)
    }

    const approved = (data) => {
        let id = data._id;
        setGetReqLoading(true);

        if (activeTab == '1') {
            AuditRequestServices.requestApproved(id).then((resp) => {
                const message = resp.message ? resp.message : "Something went wrong";
                logThisEvent(events.disclosure_approved_by_admin)
                getAllRequests();
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Approve Request");
                setGetReqLoading(false);
                console.log(error);
            }
            )
        } else {
            AuditRequestServices.requestResolved(id, 'admin_approved').then((resp) => {
                const message = resp.message ? resp.message : "Something went wrong";
                logThisEvent(events.disclosure_approved_by_admin)
                getAllRequests();
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Approve Request");
                setGetReqLoading(false);
                console.log(error);
            }
            )
        }
    }

    const reject = (data) => {
        let id = data._id;
        setGetReqLoading(true);

        if (activeTab == '1') {
            AuditRequestServices.requestReject(id, rejectionReason).then((resp) => {
                setRejectionReason('');
                logThisEvent(events.disclosure_rejected_by_admin)
                getAllRequests();
                const message = resp.message ? resp.message : "Something went wrong";
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Reject Request");
                setGetReqLoading(false);
                console.log(error);
            })
        } else {
            AuditRequestServices.requestResolved(id, 'admin_rejected', rejectionReason).then((resp) => {
                setRejectionReason('');
                logThisEvent(events.disclosure_rejected_by_admin)
                getAllRequests();
                const message = resp.message ? resp.message : "Something went wrong";
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Reject Request");
                setGetReqLoading(false);
                console.log(error);
            })
        }
    }

    const revert = (data) => {
        let id = data._id;
        setGetReqLoading(true);

        if (activeTab == '1') {
            AuditRequestServices.requestRevert(id, reversalReason).then((resp) => {
                setReversalReason('');
                logThisEvent(events.disclosure_reverted_by_admin)
                getAllRequests();
                const message = resp.message ? resp.message : "Something went wrong";
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Revert Request");
                setGetReqLoading(false);
                console.log(error);
            })
        }
        else {
            AuditRequestServices.requestResolved(id, 'admin_reverted', reversalReason).then((resp) => {
                setReversalReason('');
                logThisEvent(events.disclosure_reverted_by_admin)
                getAllRequests();
                const message = resp.message ? resp.message : "Something went wrong";
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Revert Request");
                setGetReqLoading(false);
                console.log(error);
            })
        }
    }

    const getAllRequests = async () => {
        try {
            setGetReqLoading(true);

            const resp = await AuditRequestServices.getAuditRequests(filter);
            const resp_conflicts = await AuditRequestServices.getAuditConflictsRequests(filter);

            _setRequests(resp);

            setRequests(resp);
            setConflictRequests(resp_conflicts);

            setPrevRequests(resp);
            setPrevConflictRequests(resp_conflicts)

            setGetReqLoading(false);
            requestsCount(resp);
            requestsConflictCount(resp_conflicts);
        } catch (err) {
            console.error({ err })
        }
    }

    const getAuditLogs = async (id) => {
        try {
            const resp = await AuditRequestServices.getAuditLogs(id);
            setAuditLogs(resp);
        } catch (err) {
            console.error({ err })
        }
    }

    const applyFilter = () => {
        try {
            getAllRequests(filter);
        } catch (error) {
            console.log(error);
        }
    }

    const clearFilter = () => {
        setFilter({});

        setRequests(prevRequests);
        setConflictRequests(prevConflictRequests)

        requestsCount(prevRequests);
        requestsConflictCount(prevConflictRequests);
    }

    const refreshTable = () => {
        applyFilter();
    }

    const requestsCount = (reqData) => {
        setPendingCount(reqData?.filter(object => { return object.auditStatus == 'auditor_approved' || object.auditStatus == 'auditor_rejected' }).length);
    }

    const requestsConflictCount = (reqData) => {
        setConflictsCount(reqData?.filter(object => { return object.auditStatus == 'admin_conflict' }).length);
    }

    useEffect(() => {
        getAllRequests()
    }, []);

    useEffect(() => {
    }, [reversalModalData]);

    const toggle = (e) => {
        // setFilterCollapse(!filterCollapse)
    }

    const searchValue = (e) => {
        if (e.keyCode === 13) {
            applyFilter()
        }
    }

    const handleDocumentUrl = (url) => {
        axios.get(apiURL + "/document?url=" + url, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('itoken'),
                'Content-Type': 'application/json',
            }
        })
            .then(async function (response) {
                const link = document.createElement("a");
                link.href = response.data.url;
                link.setAttribute(
                    "download",
                    'file'
                );
                document.body.appendChild(link);
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

            })
            .catch(function (error) {
                console.log({ error });
            });
    }

    const getAuditStatus = (status) => {
        switch (status) {
            case 'pending_audit':
                return 'Pending';
            case 'auditor_approved':
                return 'Approved';
            case 'auditor_rejected':
                return 'Rejected';
            case 'admin_conflict':
                return 'Conflict';
            default:
                return status;
        }

    }

    const getAuditAction = (action) => {
        switch (action) {
            case "auditor_approved":
                return "Approved"
            case "auditor_rejected":
                return "Rejected"
            case "admin_approved":
                return "Approved"
            case "admin_rejected":
                return "Rejected"
            case "admin_reverted":
                return "Reverted"
            default:
                return ""
        }
    }

    return (
        <div>
            <Header headerPadding='pb-4 pt-5 pt-md-5' />
            <Container>
                <Card className="bg-secondary shadow mt-4">
                    <CardHeader onClick={toggle} data-type="collapseBanner">
                        <h3 className="mb-0 d-flex align-items-center cursor-pointer">{audit_screen.sub_heading}</h3>
                    </CardHeader>
                    <Collapse isOpen={filterCollapse}>
                        <CardBody>
                            <Row>
                                <Col className="order-xl-1">
                                    <Row>
                                        <div className="col-md-4 mt-2">
                                            <label htmlFor="fromDate"><b>{audit_screen.label_firm}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={audit_screen.input_placeholder_firm}
                                                    type="text" style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, firm: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.firm ? filter.firm : ''}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label htmlFor="fromDate"><b>{audit_screen.label_company_name}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={audit_screen.input_placeholder_name} type="text"
                                                    style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, companyName: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.companyName ? filter.companyName : ''}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label htmlFor="fromDate"><b>{audit_screen.label_email}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={audit_screen.input_placeholder_email}
                                                    type="text" style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, email: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.email ? filter.email : ''}
                                                />
                                            </InputGroup>
                                        </div>

                                    </Row>
                                    <Row className="mt-3 align-items-end">
                                        <div className="col-md-6 mt-2" >
                                            <Button color="danger" size="l" onClick={clearFilter}>
                                                {audit_screen.button_clear}
                                            </Button>
                                            <Button color="default" size="l" onClick={() => applyFilter()}>
                                                {audit_screen.button_search}
                                            </Button>
                                        </div>

                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Collapse>
                </Card>
            </Container>

            <Container>
                <Row>
                    <Col className="order-xl-1 mt-4">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col ms="8" xs="6">
                                        <h3 className="mb-0 d-flex align-items-center">{audit_screen.cardheader} {getReqLoading ? <Spinner className="ml-3" size="sm" animation="grow" /> : <TbRefresh className="refresh ml-3" onClick={refreshTable} />}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Nav tabs>
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '1' ? 'active' : ''}
                                                onClick={() => { setActiveTab('1'); }}
                                            >
                                                {audit_screen.navlink_1} <span class="badge badge-pill badge-danger">{pendingCount}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '4' ? 'active' : ''}
                                                onClick={() => { setActiveTab('4'); }}
                                            >
                                                {audit_screen.navlink_4} <span class="badge badge-pill badge-danger">{conflictsCount}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{audit_screen.table_col_firm}</th>
                                                                    <th>{audit_screen.table_col_name}</th>
                                                                    <th>{audit_screen.table_col_email}</th>
                                                                    <th>{audit_screen.table_col_auditor_action}</th>
                                                                    <th>{audit_screen.table_col_request_date}</th>
                                                                    <th>{audit_screen.table_col_document}</th>
                                                                    <th>{audit_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {requests.map((item) => {
                                                                    let company = item.companyId;
                                                                    let firm = item.firm;
                                                                    if (item.auditStatus == 'auditor_rejected' || item.auditStatus == 'auditor_approved') {
                                                                        return (
                                                                            <tr className="text-center">

                                                                                <th>{firm?.name}</th>
                                                                                <th>{company?.companyName}</th>
                                                                                <td>{company?.email}</td>
                                                                                <th>{getAuditStatus(item?.auditStatus)}</th>
                                                                                {/* <td>{company?.country}</td> */}
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                                                                <td><a className="hyperlink" onClick={() => handleDocumentUrl(item.url)}>View</a></td>

                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        disabled={getReqLoading}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {audit_screen.button_view_detail}
                                                                                    </button>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                                                                                        <button
                                                                                            onClick={() => { approved(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 approved'>
                                                                                            <i class="fas fa-check"></i> {audit_screen.button_approve}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                                                                                        <button
                                                                                            // onClick={() => { reject(item) }}
                                                                                            onClick={() => { rejectionConfirmation(item) }}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 rejected'>
                                                                                            <i class="fas fa-times"></i> {audit_screen.button_reject}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                                                                                        <button
                                                                                            // onClick={() => { revert(item) }}
                                                                                            onClick={() => { reversalConfirmation(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                            <i class="fas fa-undo"></i> {audit_screen.button_revert}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {pendingCount == 0 && <div className="text-center mt-3">
                                                            <b>{audit_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{audit_screen.table_col_firm}</th>
                                                                    <th>{audit_screen.table_col_name}</th>
                                                                    <th>{audit_screen.table_col_email}</th>
                                                                    <th>{audit_screen.table_col_status}</th>
                                                                    <th>{audit_screen.table_col_request_date}</th>
                                                                    <th>{audit_screen.table_col_document}</th>
                                                                    <th>{audit_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {conflictRequests.map((item) => {
                                                                    let company = item.companyId;
                                                                    let firm = item.firm;
                                                                    if (item.auditStatus == 'admin_conflict') {
                                                                        return (
                                                                            <tr className="text-center">

                                                                                <th>{firm?.name}</th>
                                                                                <th>{company?.companyName}</th>
                                                                                <td>{company?.email}</td>
                                                                                <th>{getAuditStatus(item?.auditStatus)}</th>
                                                                                {/* <td>{company?.country}</td> */}
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                                                                <td><a className="hyperlink" onClick={() => handleDocumentUrl(item.url)}>View</a></td>

                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        disabled={getReqLoading}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {audit_screen.button_view_detail}
                                                                                    </button>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                                                                                        <button
                                                                                            onClick={() => { approved(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 approved'>
                                                                                            <i class="fas fa-check"></i> {audit_screen.button_approve}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                                                                                        <button
                                                                                            onClick={() => { rejectionConfirmation(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 rejected'>
                                                                                            <i class="fas fa-times"></i> {audit_screen.button_reject}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                                                                                        <button
                                                                                            onClick={() => { reversalConfirmation(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                            <i class="fas fa-undo"></i> {audit_screen.button_revert}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {conflictsCount == 0 && <div className="text-center mt-3">
                                                            <b>{audit_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* View Detail Modal */}
            <Modal isOpen={modalViewDetailIsOpen} centered fullscreen size="lg">
                <ModalHeader closeButton className="shadow mb-3">
                    <h4>{audit_screen.modal_details_heading}</h4>
                </ModalHeader>
                <ModalBody className="pt-2 pb-0">
                    <div className="">
                        <Container >
                            <Row className="">
                                <h2>Details</h2>
                            </Row>
                            <Row className="mb-4" xs="1" md="2" lg="3">

                                <Col className="my-2">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Company Name</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.companyId?.companyName}</div>
                                    </Row>
                                </Col>
                                <Col className="my-2">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Type</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.companyId?.type}</div>
                                    </Row>
                                </Col>
                                <Col className="my-2">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Country</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.companyId?.country}</div>
                                    </Row>
                                </Col>
                                <Col className="my-2">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Company Email</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.companyId?.email}</div>
                                    </Row>
                                </Col >
                                <Col className="my-2">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Request Date</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.createdAt ? moment(veiwDetailData.createdAt).format("YYYY-MM-DD") : "N/A"}</div>
                                    </Row>
                                </Col>
                                <Col className="my-2">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Disclosure</div>
                                    </Row>
                                    <Row>
                                        <a className="hyperlink" style={{ fontSize: "0.95em", fontWeight: '700' }} onClick={() => handleDocumentUrl(veiwDetailData.url)}>View</a>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <h2>Audit Trail</h2>
                            </Row>
                            <Row>
                                <Table hover responsive bordered striped size="sm" className="requests-table">
                                    <thead>
                                        <tr>

                                            <th>Organization</th>
                                            <th>User</th>
                                            <th>Action</th>
                                            <th>Reason</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {auditLogs.map((log) => {
                                            return (
                                                <tr className="text-center">
                                                    <th>{log?.firm ? log.firm?.name : 'Omaxx'}</th>
                                                    <td>{log?.firmUser ? log?.firmUser?.name : log?.apUser?.name}</td>
                                                    <td>{getAuditAction(log?.action)}</td>
                                                    <td>{log?.reason ? log?.reason : 'N/A'}</td>
                                                    <td>{moment(log.createdAt).format('YYYY-MM-DD')}</td>
                                                </tr>
                                            )
                                        })}
                                        {auditLogs.length == 0 && <tr className="text-center">
                                            <td colSpan="5">No Audit Logs Found</td>
                                        </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Container>
                    </div>
                </ModalBody >
                <ModalFooter>
                    <>
                        <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                            <Button disabled={getReqLoading} color="primary" onClick={() => {
                                approved(veiwDetailData);
                                setModalViewDetailIsOpen(false);
                            }}>
                                {audit_screen.button_approve}
                            </Button>
                        </PermissionsGate>

                        <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                            <Button disabled={getReqLoading} onClick={() => {
                                rejectionConfirmation(veiwDetailData)
                                setModalViewDetailIsOpen(false)
                            }} color="danger">
                                {audit_screen.button_reject}
                            </Button>
                        </PermissionsGate>

                        <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                            <Button disabled={getReqLoading} onClick={() => {
                                reversalConfirmation(veiwDetailData)
                                setModalViewDetailIsOpen(false)
                            }} color="info">
                                {audit_screen.button_revert}
                            </Button>
                        </PermissionsGate>
                    </>


                    <Button color="secondary" onClick={() => { setModalViewDetailIsOpen(false); setRejectionReason(""); }}>
                        {audit_screen.button_close}
                    </Button>
                </ModalFooter>
            </Modal >

            {/* Reversal Modal */}
            <Modal isOpen={reversalModalIsOpen} centered fullscreen size="lg">
                <ModalHeader closeButton className="shadow mb-3">
                    <h4>{audit_screen.modal_reversal_heading}</h4>
                </ModalHeader>
                <ModalBody className="pt-0 pb-0">
                    <div className="">
                        <Container >
                            <Row >
                                <Col className="my-3">
                                    <Row>
                                        <label className="form-control-label required" htmlFor="input-username">
                                            {audit_screen.modal_form_label_reason}
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-username"
                                            placeholder="Enter Reversal Reason"
                                            type='textarea'
                                            autoComplete="off"
                                            defaultValue={reversalReason}
                                            onKeyUp={(e) => { setReversalReason(e.target.value); }}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <>
                        <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                            <Button
                                disabled={reversalReason == '' || getReqLoading}
                                onClick={() => {
                                    revert(reversalModalData)
                                    setReversalModalIsOpen(false)
                                }}
                                color="primary"
                            >
                                <i class="fas fa-undo"></i> {audit_screen.button_revert}
                            </Button>
                        </PermissionsGate>
                    </>


                    <Button color="secondary" onClick={() => { setReversalModalIsOpen(false) }}>
                        {audit_screen.button_close}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Rejection Modal */}
            <Modal isOpen={rejectionModalIsOpen} centered fullscreen size="lg">
                <ModalHeader closeButton className="shadow mb-3">
                    <h4>{audit_screen.modal_rejection_heading}</h4>
                </ModalHeader>
                <ModalBody className="pt-0 pb-0">
                    <div className="">
                        <Container >
                            <Row >
                                <Col className="my-3">
                                    <Row>
                                        <label className="form-control-label required" htmlFor="input-username">
                                            {audit_screen.modal_form_label_reason}
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-username"
                                            placeholder="Enter Rejection Reason"
                                            type='textarea'
                                            autoComplete="off"
                                            defaultValue={rejectionReason}
                                            onKeyUp={(e) => { setRejectionReason(e.target.value); }}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <>
                        <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                            <Button
                                disabled={rejectionReason == '' || getReqLoading}
                                onClick={() => {
                                    reject(rejectionModalData)
                                    setRejectionModalIsOpen(false)
                                }}
                                color="danger"
                            >
                                <i class="fas fa-times"></i> {audit_screen.button_reject}
                            </Button>
                        </PermissionsGate>
                    </>
                    <Button color="secondary" onClick={() => { setRejectionModalIsOpen(false) }}>
                        {audit_screen.button_close}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default Audit;