
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Container,
} from "reactstrap";
import { ConfigContext } from "../../Context"

const AdminNavbar = () => {

  const { config } = React.useContext(ConfigContext);

  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark " expand="md">
        <Container className="px-4 justify-content-center">
          <NavbarBrand to="/" tag={Link}>
            <img
              style={{ height: 64 }}
              alt={config.authNav?.imgAlt}
              src={require("../../assets/img/brand/logo.png").default}
            />
          </NavbarBrand>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
