import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react"
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Auth from './helpers/Auth';
import { ConfigContext } from "Context.js";
import routes from './routes';
import PermissionsGate from "components/Permissions/PermissionsGate";
import { ToastContainer, toast } from 'react-toastify';
import clientConfig from './config.json';

function App() {

    var authSubscriber;
    const [config, setConfig] = useState(clientConfig.client)
    const [displayMessage, setDisplayMessage] = useState(true);
    const [noAuthRequiredRoutes, setNoAuthRequiredRoutes] = useState(['/auth/login', '/auth/forgot-password', '/auth/reset-password', '/auth/verify-email'])

    const authChecker = useCallback(() => {
        authSubscriber = setInterval(() => {

            // authentication check not required for these routes
            if (noAuthRequiredRoutes.includes(window.location.pathname)) return;

            let authStatus = Auth.checkAuth();

            // session about to expire
            if (authStatus.displayMessage && displayMessage) {
                toast.info('Your session is about to expire! Click here to restart your session.', {
                    position: "top-center",
                    autoClose: 120000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 1,
                    theme: "light",
                    limit: 1,
                    onClick: () => Auth.refreshToken(),
                });
                setDisplayMessage(false)
            }

            // logout user
            if (authStatus.logOut) {
                Auth.signout();
                window.location.reload();
            }

        }, 1000)
    }, [displayMessage])

    useEffect(() => {
        authChecker();

        return () => clearInterval(authSubscriber);

    }, [displayMessage])

    const getAvailableRoutes = () => {

        let filteredRoutes = routes.filter(e => {
            if (e?.permission) {
                if (PermissionsGate({ isFunction: true, hasPermission: e?.permission })) return e;
            }
            return null;
        })

        return '/admin' + filteredRoutes[0].path
    }

    let isAuthenticated = Auth.getAuth();
    if (isAuthenticated === undefined) {
        return (
            <div className="App">
                <ConfigContext.Provider value={{ config }}>
                    <Router>
                        <Switch>
                            <Route path="/auth/*" render={(props) => <AuthLayout {...props} />} />
                            <Route path="*">
                                <Redirect to="/auth/login" />
                            </Route>
                        </Switch>
                    </Router>
                </ConfigContext.Provider>
            </div>
        );
    }
    else {
        return (
            <div className="App">
                <ToastContainer />
                <ConfigContext.Provider value={{ config }}>
                    <Router>
                        <Switch>
                            <Route path="/admin/*" render={(props) => <AdminLayout {...props} />} />
                            <Route path="*">
                                <Redirect to={getAvailableRoutes()} />
                            </Route>
                        </Switch>
                    </Router>
                </ConfigContext.Provider>
            </div>
        );
    }
}

export default App;