import Header from "../components/Headers/ConnectionHeader.js";
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Collapse,
    FormGroup
} from "reactstrap";
import { logThisEvent, events } from "helpers/Analytics";
import { campaign_aml_screen } from "../content.js";
import { toast } from "react-toastify";
import moment from "moment";
import axios from 'axios';
import AMLRequestServices from 'services/AMLRequests.js';
import { Spinner } from "reactstrap"
import PermissionsGate from "../components/Permissions/PermissionsGate.js";
import PERMISSIONS from "../components/Permissions/Permissions.js";
import { TbRefresh } from "react-icons/tb";

const apiURL = process.env.REACT_APP_API_URL + "/api";

const CampaignVerification = (props) => {

    const [activeTab, setActiveTab] = useState('1');
    const [requests, setRequests] = useState([]);

    const [conflictRequests, setConflictRequests] = useState([]);
    const [_requests, _setRequests] = useState([]);

    const [prevRequests, setPrevRequests] = useState([]);
    const [prevConflictRequests, setPrevConflictRequests] = useState([]);

    const [rejectionModalIsOpen, setRejectionModalIsOpen] = useState(false);
    const [rejectionModalData, setRejectionModalData] = useState({});

    const [campaignCategories, setCampaignCategories] = useState([])
    const [modalViewDetailIsOpen, setModalViewDetailIsOpen] = useState(false);
    const [veiwDetailData, setVeiwDetailData] = useState({});
    const [getReqLoading, setGetReqLoading] = useState(false);
    const [pendingCount, setPendingCount] = useState(0);
    const [conflictsCount, setConflictsCount] = useState(0);
    const [filter, setFilter] = useState({});
    const [filterCollapse, setFilterCollapse] = useState(true);
    const [rejectionReason, setRejectionReason] = useState("");
    const [auditLogs, setAuditLogs] = useState([]);

    useEffect(() => {
        const _fetchCampaignCategories = () => {
            axios.get(apiURL + "/campaign-categories", {
                headers: {
                    "Authorization": localStorage.getItem("itoken")
                }
            })
                .then(function (response) {
                    setCampaignCategories(response.data.categories)
                })
                .catch(function (error) {
                    console.log({ error });

                });
        }
        _fetchCampaignCategories();
        getAllRequests()
    }, [])

    const viewDetail = (data) => {
        setModalViewDetailIsOpen(true);
        getAuditLogs(data._id);
        setVeiwDetailData(data)
    }

    const rejectionConfirmation = (data) => {
        setRejectionModalIsOpen(true);
        setRejectionModalData(data)
    }

    const approved = (data) => {
        let id = data._id;
        setGetReqLoading(true);

        if (activeTab == 1) {
            AMLRequestServices.campaignRequestApproved(id).then((resp) => {
                logThisEvent(events.campaign_approved)
                getAllRequests();
                toast.success("Request Approved Successfully");
            }).catch((error) => {
                toast.error("Failed to Approve Request");
                setGetReqLoading(false);
                console.log(error);
            }
            )
        } else {
            AMLRequestServices.campaignRequestConflictResolved(id, 'approve').then((resp) => {
                const message = resp.message ? resp.message : "Something went wrong";
                logThisEvent(events.campaign_approved)
                getAllRequests();
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Approve Request");
                setGetReqLoading(false);
                console.log(error);
            }
            )
        }
    }

    const reject = (data) => {
        let id = data._id;
        setGetReqLoading(true);

        if (activeTab == 1) {
            AMLRequestServices.campaignRequestRejected(id, rejectionReason).then((resp) => {
                setRejectionReason("");
                logThisEvent(events.campaign_rejected)
                getAllRequests();
                toast.success("Request Rejected Successfully");
            }).catch((error) => {
                toast.error("Failed to Reject Request");
                setGetReqLoading(false);
                console.log(error);
            })
        } else {
            AMLRequestServices.campaignRequestConflictResolved(id, 'reject', rejectionReason).then((resp) => {
                setRejectionReason("");
                const message = resp.message ? resp.message : "Something went wrong";
                logThisEvent(events.campaign_rejected)
                getAllRequests();
                toast.success(message);
            }).catch((error) => {
                toast.error("Failed to Approve Request");
                setGetReqLoading(false);
                console.log(error);
            }
            )
        }
    }

    const getAllRequests = async () => {
        try {
            setGetReqLoading(true);

            const resp = await AMLRequestServices.getCampaignVerificationRequests(filter);
            const resp_conflicts = await AMLRequestServices.getCampaignVerificationConflictsRequests(filter);
            _setRequests(resp);

            setRequests(resp);
            setConflictRequests(resp_conflicts);

            setPrevRequests(resp);
            setPrevConflictRequests(resp_conflicts)

            setGetReqLoading(false);

            requestsCount(resp);
            requestsConflictCount(resp_conflicts);
        } catch (err) {
            console.error({ err })
        }
    }

    const getAuditLogs = async (id) => {
        try {
            const resp = await AMLRequestServices.getAuditLogs(id);
            setAuditLogs(resp);
        } catch (err) {
            console.error({ err })
        }
    }

    const applyFilter = () => {
        try {
            getAllRequests(filter);
        } catch (error) {
            console.log(error);
        }

    }

    const clearFilter = () => {
        setFilter({});

        setRequests(prevRequests);
        setConflictRequests(prevConflictRequests);

        requestsCount(prevRequests);
        requestsConflictCount(prevConflictRequests);
    }

    const refreshTable = () => {
        applyFilter();
    }

    const requestsCount = (reqData) => {
        setPendingCount(reqData?.filter(object => { return object.requestStatus == 'pending' }).length);
    }

    const requestsConflictCount = (reqData) => {
        setConflictsCount(reqData?.filter(object => { return object.requestStatus == 'conflict' }).length);
    }

        ;

    const toggle = (e) => {
        // setFilterCollapse(!filterCollapse)
    }

    const searchValue = (e) => {
        if (e.keyCode === 13) {
            applyFilter()
        }
    }

    const handleDocumentUrl = (url) => {
        axios.get(apiURL + "/document?url=" + url, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('itoken'),
                'Content-Type': 'application/json',
            }
        })
            .then(async function (response) {
                const link = document.createElement("a");
                link.href = response.data.url;
                link.setAttribute(
                    "download",
                    'file'
                );
                document.body.appendChild(link);
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

            })
            .catch(function (error) {
                console.log({ error });
            });
    }

    const getAuditAction = (action) => {
        switch (action) {
            case "approve":
                return "Approved"
            case "reject":
                return "Rejected"
            default:
                return ""
        }
    }


    return (
        <div>
            <Header headerPadding='pb-4 pt-5 pt-md-5' />
            <Container>
                <Card className="bg-secondary shadow mt-4">
                    <CardHeader onClick={toggle} data-type="collapseBanner">
                        <h3 className="mb-0 d-flex align-items-center cursor-pointer">{campaign_aml_screen.sub_heading}</h3>
                    </CardHeader>
                    <Collapse isOpen={filterCollapse}>
                        <CardBody>
                            <Row>
                                <Col className="order-xl-1">
                                    <Row>
                                        <div className="col-md-4 mt-2">
                                            <label htmlFor="fromDate"><b>{campaign_aml_screen.label_title}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={campaign_aml_screen.input_placeholder_title} type="text"
                                                    style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, title: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.title ? filter.title : ''}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label htmlFor="fromDate"><b>{campaign_aml_screen.label_company}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <InputGroupAddon addonType="prepend" >
                                                    <InputGroupText>
                                                        <i className="fas fa-search" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder={campaign_aml_screen.input_placeholder_company}
                                                    type="text" style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, company: e.target.value }))}
                                                    onKeyDown={(e) => searchValue(e)}
                                                    value={filter.company ? filter.company : ''}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="col-md-4 mt-2">
                                            <label htmlFor="fromDate"><b>{campaign_aml_screen.label_category}</b></label>
                                            <InputGroup className="input-group-alternative" >
                                                <Input
                                                    placeholder="Select a category"
                                                    type="select"
                                                    style={{ width: 'auto' }}
                                                    onChange={(e) => setFilter(filter => ({ ...filter, category: e.target.value }))}
                                                    value={filter.category ? filter.category : ''}
                                                >
                                                    <option key="placeholder" value="" disabled selected>Select a Category</option>
                                                    <option key="all" value="all">All</option>
                                                    {campaignCategories.map((category, index) => (
                                                        <option key={index} value={category._id}>{category.name}</option>
                                                    ))}
                                                </Input>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mt-3 align-items-end">
                                        <div className="col-md-6 mt-2" >
                                            <Button color="danger" size="l" onClick={clearFilter}>
                                                {campaign_aml_screen.button_clear}
                                            </Button>
                                            <Button color="default" size="l" onClick={() => applyFilter()}>
                                                {campaign_aml_screen.button_search}
                                            </Button>
                                        </div>

                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Collapse>
                </Card>
            </Container>

            <Container>
                <Row>
                    <Col className="order-xl-1 mt-4">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col ms="8" xs="6">
                                        <h3 className="mb-0 d-flex align-items-center">{campaign_aml_screen.cardheader} {getReqLoading ? <Spinner className="ml-3" size="sm" animation="grow" /> : <TbRefresh className="refresh ml-3" onClick={refreshTable} />}</h3>
                                    </Col>

                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Nav tabs>
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '1' ? 'active' : ''}
                                                onClick={() => { setActiveTab('1'); }}
                                            >
                                                {campaign_aml_screen.navlink_1} <span class="badge badge-pill badge-danger">{pendingCount}</span>
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '2' ? 'active' : ''}
                                                onClick={() => { setActiveTab('2'); }}
                                            >
                                                {campaign_aml_screen.navlink_2} <span class="badge badge-pill badge-danger">{approvedCount}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '3' ? 'active' : ''}
                                                onClick={() => { setActiveTab('3'); }}
                                            >
                                                {campaign_aml_screen.navlink_3} <span class="badge badge-pill badge-danger">{rejectCount}</span>
                                            </NavLink>
                                        </NavItem> */}
                                        <NavItem className="cursor-pointer">
                                            <NavLink
                                                className={activeTab == '4' ? 'active' : ''}
                                                onClick={() => { setActiveTab('4'); }}
                                            >
                                                {campaign_aml_screen.navlink_4} <span class="badge badge-pill badge-danger">{conflictsCount}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>
                                                                        <input onClick={togglePendingCheckBox} type="checkbox" class="my-checkbox" value="pending" />
                                                                    </th> */}
                                                                    <th>{campaign_aml_screen.table_col_title}</th>
                                                                    <th>{campaign_aml_screen.table_col_company}</th>
                                                                    <th>{campaign_aml_screen.table_col_target}</th>
                                                                    {/* <th>{campaign_aml_screen.table_col_start}</th>
                                                                    <th>{campaign_aml_screen.table_col_end}</th> */}
                                                                    <th>{campaign_aml_screen.table_col_request_date}</th>
                                                                    <th>{campaign_aml_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {requests.map((item) => {
                                                                    let campaign = item.campaignId;
                                                                    let company = item.campaignId?.companyId;
                                                                    if (item.requestStatus == 'pending') {
                                                                        return (
                                                                            <tr className="text-center">
                                                                                {/* <th>
                                                                                    <input onClick={toggleSinglePendingCheckBox} checked={checkedIds.includes(item._id)} type="checkbox" class="my-checkbox" value={JSON.stringify({ isBatch: item.isBatch, id: item._id, batchNo: item.batchNo })} />
                                                                                </th> */}
                                                                                <th>{campaign?.title}</th>
                                                                                <td>{campaign?.companyId?.companyName}</td>
                                                                                <td>{campaign?.targetAmount} {company?.investmentToken?.symbol}</td>
                                                                                {/* <td>{moment(campaign?.startDate).format('YYYY-MM-DD')}</td>
                                                                                <td>{moment(campaign?.deadlineDate).format('YYYY-MM-DD')}</td> */}
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>

                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        disabled={getReqLoading}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {campaign_aml_screen.button_view_detail}
                                                                                    </button>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.approve_req]}>
                                                                                        <button
                                                                                            onClick={() => { approved(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 approved'>
                                                                                            <i class="fas fa-check"></i> {campaign_aml_screen.button_approve}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.reject_req]}>
                                                                                        <button
                                                                                            onClick={() => { rejectionConfirmation(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 rejected'>
                                                                                            <i class="fas fa-times"></i> {campaign_aml_screen.button_reject}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {pendingCount == 0 && <div className="text-center mt-3">
                                                            <b>{campaign_aml_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        {/* <TabPane tabId="2">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{campaign_aml_screen.table_col_title}</th>
                                                                    <th>{campaign_aml_screen.table_col_company}</th>
                                                                    <th>{campaign_aml_screen.table_col_target}</th>
                                                                    <th>{campaign_aml_screen.table_col_start}</th>
                                                                    <th>{campaign_aml_screen.table_col_end}</th>
                                                                    <th>{campaign_aml_screen.table_col_request_date}</th>
                                                                    <th>{campaign_aml_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {requests.map((item) => {
                                                                    if (item.requestStatus == 'approved') {
                                                                        let campaign = item.campaignId;
                                                                        return (
                                                                            <tr className="text-center">

                                                                                <th>{campaign?.title}</th>
                                                                                <td>{campaign?.companyId?.companyName}</td>
                                                                                <td>{campaign?.targetAmount} BUSD</td>
                                                                                <td>{moment(campaign?.startDate).format('YYYY-MM-DD')}</td>
                                                                                <td>{moment(campaign?.deadlineDate).format('YYYY-MM-DD')}</td>
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>


                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {campaign_aml_screen.button_view_detail}
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {approvedCount == 0 && <div className="text-center mt-3">
                                                            <b>{campaign_aml_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{campaign_aml_screen.table_col_title}</th>
                                                                    <th>{campaign_aml_screen.table_col_company}</th>
                                                                    <th>{campaign_aml_screen.table_col_target}</th>
                                                                    <th>{campaign_aml_screen.table_col_start}</th>
                                                                    <th>{campaign_aml_screen.table_col_end}</th>
                                                                    <th>{campaign_aml_screen.table_col_request_date}</th>
                                                                    <th>{campaign_aml_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {requests.map((item) => {
                                                                    let campaign = item.campaignId;
                                                                    if (item.requestStatus == 'rejected') {
                                                                        return (
                                                                            <tr className="text-center">
                                                                                <th>{campaign?.title}</th>
                                                                                <td>{campaign?.companyId?.companyName}</td>
                                                                                <td>{campaign?.targetAmount} BUSD</td>
                                                                                <td>{moment(campaign?.startDate).format('YYYY-MM-DD')}</td>
                                                                                <td>{moment(campaign?.deadlineDate).format('YYYY-MM-DD')}</td>
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>

                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {campaign_aml_screen.button_view_detail}
                                                                                    </button>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.revert_req]}>
                                                                                        <button
                                                                                            onClick={() => { revert(item) }}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 approved'>
                                                                                            <i class="fas fa-undo"></i> {campaign_aml_screen.button_revert}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {rejectCount == 0 && <div className="text-center mt-3">
                                                            <b>{campaign_aml_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane> */}
                                        <TabPane tabId="4">
                                            <Row>
                                                <Col sm="12">
                                                    <Card body>
                                                        <Table hover responsive className="requests-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{campaign_aml_screen.table_col_title}</th>
                                                                    <th>{campaign_aml_screen.table_col_company}</th>
                                                                    <th>{campaign_aml_screen.table_col_target}</th>
                                                                    {/* <th>{campaign_aml_screen.table_col_start}</th>
                                                                    <th>{campaign_aml_screen.table_col_end}</th> */}
                                                                    <th>{campaign_aml_screen.table_col_request_date}</th>
                                                                    <th>{campaign_aml_screen.table_col_actions}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {conflictRequests.map((item) => {
                                                                    let campaign = item.campaignId;
                                                                    if (item.requestStatus == 'conflict') {
                                                                        return (
                                                                            <tr className="text-center">
                                                                                <th>{campaign?.title}</th>
                                                                                <td>{campaign?.companyId?.companyName}</td>
                                                                                <td>{campaign?.targetAmount} company</td>
                                                                                {/* <td>{moment(campaign?.startDate).format('YYYY-MM-DD')}</td>
                                                                                <td>{moment(campaign?.deadlineDate).format('YYYY-MM-DD')}</td> */}
                                                                                <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>

                                                                                <td className="request-action-btn-box">
                                                                                    <button
                                                                                        onClick={() => { viewDetail(item) }}
                                                                                        disabled={getReqLoading}
                                                                                        className='btn-sm py-1 px-2 border-0 text-white mr-1 view-detail'>
                                                                                        <i class="fas fa-info"></i> {campaign_aml_screen.button_view_detail}
                                                                                    </button>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.approve_req]}>
                                                                                        <button
                                                                                            onClick={() => { approved(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 approved'>
                                                                                            <i class="fas fa-check"></i> {campaign_aml_screen.button_approve}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                    <PermissionsGate hasPermission={[PERMISSIONS.reject_req]}>
                                                                                        <button
                                                                                            onClick={() => { rejectionConfirmation(item) }}
                                                                                            disabled={getReqLoading}
                                                                                            className='btn-sm py-1 px-2 border-0 text-white mr-1 rejected'>
                                                                                            <i class="fas fa-times"></i> {campaign_aml_screen.button_reject}
                                                                                        </button>
                                                                                    </PermissionsGate>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                        {conflictsCount == 0 && <div className="text-center mt-3">
                                                            <b>{campaign_aml_screen.table_no_request}</b>
                                                        </div>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* View Detail Modal */}
            <Modal isOpen={modalViewDetailIsOpen} centered fullscreen size="lg">
                <ModalHeader closeButton className="shadow mb-3">
                    <h4>{campaign_aml_screen.modal_details_heading}</h4>
                </ModalHeader>
                <ModalBody className="pt-0 pb-0">
                    <div >
                        <Container >
                            <Row className="d-flex justify-content-center">
                                <h2 >Details</h2>
                            </Row>
                            <Row xs="1" md="2" lg="3">
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Campaign Title</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.title}</div>
                                    </Row>
                                </Col>
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Company Name</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.companyId?.companyName}</div>
                                    </Row>
                                </Col >
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Campaign Video</div>
                                    </Row>
                                    <Row>
                                        <a className="hyperlink" style={{ fontSize: "0.95em", fontWeight: '700' }} href={veiwDetailData.campaignId?.campaignVideoUrl} target="_blank" >Link</a>
                                    </Row>
                                </Col>
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Target Amount</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.targetAmount} {veiwDetailData.campaignId?.companyId?.investmentToken?.symbol}</div>
                                    </Row>
                                </Col>
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Equity</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.equityPercentage}%</div>
                                    </Row>
                                </Col>
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Pre Money Valuation</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.premoneyValuation}</div>
                                    </Row>
                                </Col>
                                {/* <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Token Price</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.tokenPrice}</div>
                                    </Row>
                                </Col>
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Token Symbol</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.tokenSymbol}</div>
                                    </Row>
                                </Col>
                                <Col className="my-3">
                                    <Row>
                                        <div className="" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)", fontWeight: "500" }}>Start Date</div>
                                    </Row>
                                    <Row>
                                        <div className="" style={{ fontSize: "0.95em", fontWeight: '700' }}>{veiwDetailData.campaignId?.startDate ? moment(veiwDetailData.campaignId?.startDate).format("YYYY-MM-DD") : "N/A"}</div>
                                    </Row>
                                </Col> */}
                            </Row>

                            <hr />

                            <Row className="d-flex justify-content-center">
                                <h2>Documents</h2>
                            </Row>
                            <Row className="centered-content" xs="1" lg="3">
                                <strong className="mt-2 hyperlink" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)" }} onClick={() => handleDocumentUrl(veiwDetailData.campaignId.pitchDeckDocumentUrl, `${veiwDetailData.campaignId.title} - pitch-deck-document`)}>Pitch Deck Document</strong>
                                <strong className="mt-2 hyperlink" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)" }} onClick={() => handleDocumentUrl(veiwDetailData.campaignId.financialDocumentUrl, `${veiwDetailData.campaignId.title} - financial-document`)}>Financial Document</strong>
                                <strong className="mt-2 hyperlink" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)" }} onClick={() => handleDocumentUrl(veiwDetailData.campaignId.businessPlanDocumentUrl, `${veiwDetailData.campaignId.title} - business-plan-document`)}>Business Plan Document</strong>
                                {/* {veiwDetailData?.campaignId?.otherDocumentUrl && <strong className="mt-2 hyperlink" style={{ fontSize: "0.9em", color: "rgb(209 167 67 / 80%)" }} onClick={() => handleDocumentUrl(veiwDetailData.campaignId.otherDocumentUrl, `${veiwDetailData.campaignId.title} - other-document`)}>Other Document</strong>} */}
                            </Row>
                            <hr />
                            <div className="d-flex justify-content-center w-100">
                                <h2>Audit Trail</h2>
                            </div>

                            <Row>
                                <Table hover responsive bordered striped size="sm" className="requests-table">
                                    <thead>
                                        <tr>

                                            <th>User</th>
                                            <th>Action</th>
                                            <th>Reason</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {auditLogs.map((log) => {
                                            return (
                                                <tr className="text-center">
                                                    <td>{log?.apUser?.name}</td>
                                                    <td>{getAuditAction(log?.action)}</td>
                                                    <td>{log?.reason ? log?.reason : 'N/A'}</td>
                                                    <td>{moment(log.createdAt).format('YYYY-MM-DD')}</td>
                                                </tr>
                                            )
                                        })}
                                        {auditLogs.length == 0 && <tr className="text-center">
                                            <td colSpan="5">No Audit Logs Found</td>
                                        </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Container>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <>
                        <PermissionsGate hasPermission={[PERMISSIONS.approve_req]}>
                            <Button color="primary"
                                disabled={getReqLoading}
                                onClick={() => {
                                    approved(veiwDetailData);
                                    setModalViewDetailIsOpen(false);
                                }}>
                                {campaign_aml_screen.button_approve}
                            </Button>
                        </PermissionsGate>

                        <PermissionsGate hasPermission={[PERMISSIONS.reject_req]}>
                            <Button
                                disabled={getReqLoading}
                                onClick={() => {
                                    rejectionConfirmation(veiwDetailData)
                                    setModalViewDetailIsOpen(false)
                                }} color="danger">
                                {campaign_aml_screen.button_reject}
                            </Button>
                        </PermissionsGate>
                    </>
                    {/* } */}


                    <Button color="secondary" onClick={() => { setModalViewDetailIsOpen(false) }}>
                        {campaign_aml_screen.button_close}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Rejection Modal */}
            <Modal isOpen={rejectionModalIsOpen} centered fullscreen size="lg">
                <ModalHeader closeButton className="shadow mb-3">
                    <h4>{campaign_aml_screen.modal_rejection_heading}</h4>
                </ModalHeader>
                <ModalBody className="pt-0 pb-0">
                    <div className="">
                        <Container >
                            <Row >
                                <Col className="my-3">
                                    <Row>
                                        <label className="form-control-label required" htmlFor="input-username">
                                            {campaign_aml_screen.modal_form_label_reason}
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-username"
                                            placeholder="Enter Rejection Reason"
                                            type='textarea'
                                            autoComplete="off"
                                            defaultValue={rejectionReason}
                                            onKeyUp={(e) => { setRejectionReason(e.target.value); }}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <>
                        <PermissionsGate hasPermission={[PERMISSIONS.review_audit_req]}>
                            <Button
                                disabled={rejectionReason == '' || getReqLoading}
                                onClick={() => {
                                    reject(rejectionModalData)
                                    setRejectionModalIsOpen(false)
                                }}
                                color="danger"
                            >
                                <i class="fas fa-times"></i> {campaign_aml_screen.button_reject}
                            </Button>
                        </PermissionsGate>
                    </>
                    <Button color="secondary" onClick={() => { setRejectionModalIsOpen(false) }}>
                        {campaign_aml_screen.button_close}
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
    )

}

export default CampaignVerification;