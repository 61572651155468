import axios from 'axios'
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { countries } from 'helpers/country-codes'

const companyLogo = "https://omaxx-assets.s3.eu-west-3.amazonaws.com/omaxx-logo/omaxx-logo.png"

// Register fonts (you can customize the fonts if needed)
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class ReportsServicesClass {

    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('itoken')
        };
    }

    // Registrations Report
    getRegistrationsReport = (fromDate, toDate) => {
        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + `/api/users-registration-report?startDate=${fromDate}&endDate=${toDate}`

                axios.get(API_PATH,
                    {
                        headers: this.headers,
                    }
                ).then((response) => {
                    if (response.status === 200) {
                        this.generateRegistrationsPDF(response.data.monthlyReport)
                        resolve(response.data.monthlyReport)

                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    generateRegistrationsPDF = (data) => {
        const content = [];

        // Add front page with company information
        content.push(
            {
                image: 'companyLogo',
                width: 100, // Set the width of the image as needed
                alignment: 'center',
                margin: [0, 0, 0, 20],
            },
            {
                text: 'Registration Report',
                alignment: 'center',
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 20],
            }
        );

        data.forEach((monthly) => {
            // Format start and end dates
            const startDate = moment(monthly.dateRange.startDate).format('DD/MM/YYYY');
            const endDate = moment(monthly.dateRange.endDate).format('DD/MM/YYYY');

            // Add content for each month
            content.push(
                { text: `${monthly.month}`, style: 'header' },
                { text: `Date Range: ${startDate} to ${endDate}`, style: 'subheader' },
                { text: '\n' },

                // Add data table
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', 'auto'],
                        body: [
                            ['Country', 'Registration Count', 'Login Count',],
                            ...monthly.data.map(({ country, registrationCount, loginCount, users }) => [
                                country,
                                registrationCount,
                                loginCount,
                                // users.length,
                                // users.length > 0 ? users[0].firstName : '-',
                            ]),
                        ],
                    },
                },
                // Add spacing after each month data
                { text: '\n\n\n' }
            );
        });

        const documentDefinition = {
            content,
            images: {
                companyLogo: companyLogo,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
            },
        };

        pdfMake.createPdf(documentDefinition).download('regestration_report.pdf');
    };

    // Investments Report
    getInvestmentsReport = (fromDate, toDate) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + `/api/user-investments-report?startDate=${fromDate}&endDate=${toDate}`

                axios.get(API_PATH,
                    {
                        headers: this.headers,
                    }
                ).then((response) => {
                    if (response.status === 200) {
                        this.generateInvestmentsPDF(response.data.yearlyReport, response.data.monthlyReport)
                        resolve(response.data.yearlyReport, response.data.monthlyReport)

                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    generateInvestmentsPDF = (yearlyReport, monthlyReports) => {
        const content = [];

        // Add front page with company information
        content.push(
            {
                image: 'companyLogo',
                width: 100, // Set the width of the image as needed
                alignment: 'center',
                margin: [0, 0, 0, 20],
            },
            {
                text: 'Investment Report',
                alignment: 'center',
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 20],
            }
        );

        // Add yearly report data
        content.push(
            { text: 'Totals', style: 'header' },
            { text: '\n' },
            {
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto'],
                    body: [
                        ['Total Investment', 'Avg Investment', 'Unique Investors'],
                        [
                            yearlyReport.totalInvestment,
                            yearlyReport.avgInvestment,
                            yearlyReport.uniqueInvestorsCount,
                        ],
                    ],
                },
            },
            { text: '\n\n\n' }
        );



        monthlyReports.forEach((monthlyReport) => {
            // Format start and end dates using moment
            const startDate = moment(monthlyReport.dateRange.startDate).format('DD/MM/YYYY');
            const endDate = moment(monthlyReport.dateRange.endDate).format('DD/MM/YYYY');

            // Add content for each monthly report
            content.push(
                { text: `${monthlyReport.month}`, style: 'header' },
                { text: `Date Range: ${startDate} to ${endDate}`, style: 'subheader' },
                { text: '\n' },

                // Check if there is data for the month
                monthlyReport.data.length > 0 ?
                    // Add data table if there is data
                    {
                        table: {
                            headerRows: 1,
                            widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                            body: [
                                ['Country', 'Investment Amount', 'Investment', 'Unique Investors', 'Avg Investment'],
                                ...monthlyReport.data.map(({ country, monthlyInvestmentAmount, monthlyInvestmentCount, monthlyUniqueInvestorsCount, monthlyAvgInvestment }) => [
                                    country,
                                    monthlyInvestmentAmount,
                                    monthlyInvestmentCount,
                                    monthlyUniqueInvestorsCount,
                                    monthlyAvgInvestment,
                                ]),
                            ],
                        },
                    } :
                    // Add message if there is no data
                    { text: 'No data available for this month', style: 'subheader' },
                // Add spacing after each monthly report data
                { text: '\n\n\n' }
            );
        });

        const documentDefinition = {
            content,
            images: {
                companyLogo: companyLogo,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
            },
        };

        pdfMake.createPdf(documentDefinition).download('investment_report.pdf');
    };

    // Trades Report
    getTradesReport = (fromDate, toDate) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + `/api/trades-report?startDate=${fromDate}&endDate=${toDate}`

                axios.get(API_PATH,
                    {
                        headers: this.headers,
                    }
                ).then((response) => {
                    if (response.status === 200) {
                        this.generateTradesPDF(response.data.monthlyReport)
                        resolve(response.data.monthlyReport)

                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    generateTradesPDF = (monthlyReports) => {
        const content = [];

        // Add front page with company information
        content.push(
            {
                image: 'companyLogo',
                width: 100, // Set the width of the image as needed
                alignment: 'center',
                margin: [0, 0, 0, 20],
            },
            {
                text: 'Trades Report',
                alignment: 'center',
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 20],
            }
        );

        monthlyReports.forEach((monthlyReport) => {
            // Format start and end dates using moment
            const startDate = moment(monthlyReport.dateRange.startDate).format('DD/MM/YYYY');
            const endDate = moment(monthlyReport.dateRange.endDate).format('DD/MM/YYYY');

            // Add content for each monthly report
            content.push(
                { text: `${monthlyReport.month}`, style: 'header' },
                { text: `Date Range: ${startDate} to ${endDate}`, style: 'subheader' },
                { text: '\n' },

                // Check if there is data for the month
                monthlyReport.data.length > 0 ?
                    // Add data table if there is data
                    {
                        table: {
                            headerRows: 1,
                            widths: ['auto', 'auto', 'auto', 'auto'],
                            body: [
                                ['Amount', 'Traded', 'Received', 'Price'],
                                ...monthlyReport.data.map(({ amount, tokenSymbol, secondaryTokenSymbol, price }) => [
                                    amount,
                                    tokenSymbol,
                                    secondaryTokenSymbol,
                                    price,
                                ]),
                            ],
                        },
                    } :
                    // Add message if there is no data
                    { text: 'No data available for this month', style: 'subheader' },
                // Add spacing after each monthly report data
                { text: '\n\n\n' }
            );
        });

        const documentDefinition = {
            content,
            images: {
                companyLogo: companyLogo,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
            },
        };

        pdfMake.createPdf(documentDefinition).download('trades_report.pdf');
    };

    // KYCs Report
    getKYCsReport = (fromDate, toDate) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + `/api/kyc-report?startDate=${fromDate}&endDate=${toDate}`

                axios.get(API_PATH,
                    {
                        headers: this.headers,
                    }
                ).then((response) => {
                    if (response.status === 200) {
                        this.generateKYCsPDF(response.data.monthlyReport)
                        resolve(response.data.monthlyReport)

                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    generateKYCsPDF = (data) => {
        const content = [];

        // Add front page with company information
        content.push(
            {
                image: 'companyLogo',
                width: 100, // Set the width of the image as needed
                alignment: 'center',
                margin: [0, 0, 0, 20],
            },
            {
                text: 'KYC Report',
                alignment: 'center',
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 20],
            }
        );

        data.forEach((monthly) => {
            // Format start and end dates
            const startDate = moment(monthly.dateRange.startDate).format('DD/MM/YYYY');
            const endDate = moment(monthly.dateRange.endDate).format('DD/MM/YYYY');

            // Add content for each month
            content.push(
                { text: `${monthly.month}`, style: 'header' },
                { text: `Date Range: ${startDate} to ${endDate}`, style: 'subheader' },
                { text: '\n' },

                // Add data table
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                        body: [
                            ['Country', 'Total', 'Success', 'Failure', 'Success Rate'],
                            ...monthly.data.map(({ country, totalCount, successCount, failureCount, successRate }) => [
                                country,
                                totalCount,
                                successCount,
                                failureCount,
                                successRate
                            ]),
                        ],
                    },
                },
                // Add spacing after each month data
                { text: '\n\n\n' }
            );
        });

        const documentDefinition = {
            content,
            images: {
                companyLogo: companyLogo,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
            },
        };

        pdfMake.createPdf(documentDefinition).download('kyc_report.pdf');
    };

    // Auditors Report
    getAuditorsReport = (fromDate, toDate) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + `/api/auditors-report?startDate=${fromDate}&endDate=${toDate}`

                axios.get(API_PATH,
                    {
                        headers: this.headers,
                    }
                ).then((response) => {
                    if (response.status === 200) {
                        this.generateAuditorsPDF(response.data.monthlyReport)
                        resolve(response.data.monthlyReport)

                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    generateAuditorsPDF = (data) => {
        const content = [];

        // Add front page with company information
        content.push(
            {
                image: 'companyLogo',
                width: 100, // Set the width of the image as needed
                alignment: 'center',
                margin: [0, 0, 0, 20],
            },
            {
                text: 'Auditors Report',
                alignment: 'center',
                fontSize: 16,
                bold: true,
                margin: [0, 0, 0, 20],
            }
        );

        data.forEach((monthly) => {
            // Format start and end dates
            const startDate = moment(monthly.dateRange.startDate).format('DD/MM/YYYY');
            const endDate = moment(monthly.dateRange.endDate).format('DD/MM/YYYY');

            // Add content for each month
            content.push(
                { text: `${monthly.month}`, style: 'header' },
                { text: `Date Range: ${startDate} to ${endDate}`, style: 'subheader' },
                { text: '\n' },

                // Add data table
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto'],
                        body: [
                            ['Country', 'Auditors Count'],
                            ...monthly.data.map(({ country, users }) => [
                                countries[country],
                                users.length,
                            ]),
                        ],
                    },
                },
                // Add spacing after each month data
                { text: '\n\n\n' }
            );
        });

        const documentDefinition = {
            content,
            images: {
                companyLogo: companyLogo,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
            },
        };

        pdfMake.createPdf(documentDefinition).download('auditors_report.pdf');
    };
};

const AnalyticsServices = new ReportsServicesClass;

export default AnalyticsServices;
