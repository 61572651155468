import Login from "views/auth/Login.js";
import Users from "views/Users";
import Firms from "views/Firms";
import Audit from "views/Audit"
import CompanyVerification from "views/CompanyVerification";
import CampaignVerification from "views/CampaignVerification";
import PERMISSIONS from "components/Permissions/Permissions";
import {
  company_requests, campaign_requests,
  userManagement, firmManagement, audit_requests,reports
} from "content";
import Reports from "views/Reports";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/company-aml-requests",
    name: company_requests,
    icon: "fas fa-landmark text-orange",
    component: CompanyVerification,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_approval_req],
  },
  {
    path: "/campaign-aml-requests",
    name: campaign_requests,
    icon: "fas fa-tasks text-dark",
    component: CampaignVerification,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_approval_req],
  },
  {
    path: "/audit-requests",
    name: audit_requests,
    icon: "fas fa-landmark text-orange",
    component: Audit,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.review_audit_req],
  },
  {
    path: "/users",
    name: userManagement,
    icon: "fa fa-users text-blue",
    component: Users,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_users],
  },
  {
    path: "/reports",
    name: reports,
    icon: "fa fa-users text-blue",
    component: Reports,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_reports],
  },
  // {
  //   path: "/firms",
  //   name: firmManagement,
  //   icon: "fa fa-users text-blue",
  //   component: Firms,
  //   layout: "/admin",
  //   roles: ["admin"],
  //   permission: [PERMISSIONS.view_firms],
  // },
];

export default routes;
