import React, { useState, useMemo, useCallback } from 'react'
import {
  Container,
  Col,
  Row,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
  Form
} from "reactstrap";
import Header from "../components/Headers/ConnectionHeader.js";
import AnalyticsServices from 'services/ReportsRequests.js';

import { reports_screen } from "../content.js";

const Reports = () => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const getRegistrationsReport = useCallback(async () => {
    try {
      setRequestLoading(true);
      await AnalyticsServices.getRegistrationsReport(fromDate, toDate).then(() => { closeModal(); setRequestLoading(false) });
    } catch (err) {
      console.error({ err })
    }
  }, [fromDate, toDate]);

  const getInvestmentsReport = useCallback(async () => {
    try {
      await AnalyticsServices.getInvestmentsReport(fromDate, toDate).then(() => { closeModal(); setRequestLoading(false) });
    } catch (err) {
      console.error({ err })
    }
  }, [fromDate, toDate]);

  const getTradesReport = useCallback(async () => {
    try {
      await AnalyticsServices.getTradesReport(fromDate, toDate).then(() => { closeModal(); setRequestLoading(false) });
    } catch (err) {
      console.error({ err })
    }
  }, [fromDate, toDate]);

  const getKYCsReport = useCallback(async () => {
    try {
      await AnalyticsServices.getKYCsReport(fromDate, toDate).then(() => { closeModal(); setRequestLoading(false) });
    } catch (err) {
      console.error({ err })
    }
  }, [fromDate, toDate]);

  const getAuditorsReport = useCallback(async () => {
    try {
      await AnalyticsServices.getAuditorsReport(fromDate, toDate).then(() => { closeModal(); setRequestLoading(false) });
    } catch (err) {
      console.error({ err })
    }
  }, [fromDate, toDate]);

  const openModal = (index) => {
    setSelectedIndex(index);
    setExportModalOpen(true);
  }

  const closeModal = () => {
    setExportModalOpen(false);
    setSelectedIndex(0);
    setFromDate('');
    setToDate('');
  }

  const metrics = useMemo(() => [
    { id: 1, name: "Registrations", action: () => getRegistrationsReport() },
    { id: 2, name: "Investments", action: () => getInvestmentsReport() },
    { id: 3, name: "Trades", action: () => getTradesReport() },
    { id: 4, name: "KYCs", action: () => getKYCsReport() },
    { id: 5, name: "Auditors", action: () => getAuditorsReport() },
    // { id: 6, name: "Transactions" },
  ], [getRegistrationsReport, getInvestmentsReport, getTradesReport, getKYCsReport, getAuditorsReport]);

  return (
    <div>
      {/* Export Report Modal */}
      <Modal isOpen={exportModalOpen} centered>
        <ModalHeader closeButton className="shadow mb-3">
          <h3>Export {metrics[selectedIndex]?.name} Report</h3>
        </ModalHeader>
        <Form id="export-form" onSubmit={metrics[selectedIndex]?.action}>
          <ModalBody className="pt-0 pb-0">
            <div>
              <Row>
                <Col className='px-0' lg={6}>
                  <label htmlFor="fromDate" className="col-sm-2 col-form-label" ><b>{reports_screen.label_From}</b></label>
                  <div className="col-auto" >
                    <Input placeholder="From" id="fromDate" type="date" onChange={(e) => setFromDate(e.target.value)}
                      value={fromDate} />
                  </div>
                </Col>

                <Col className='px-0' lg={6}>
                  <label htmlFor="toDate" className="col-sm-2 col-form-label" ><b>{reports_screen.label_To} </b></label>
                  <div className="col-auto">
                    <Input placeholder="To" id="toDate" type="date" onChange={(e) => setToDate(e.target.value)}
                      value={toDate} />
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={closeModal}>
              {reports_screen.button_cancel}
            </Button>
            <Button color="primary" disabled={requestLoading || !fromDate || !toDate} onClick={metrics[selectedIndex]?.action}>
              {reports_screen.button_download} {requestLoading && <Spinner animation="grow" size={'sm'} />}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Header headerPadding="pb-4 pt-5 pt-md-5" />
      <Container className="my-5 card  border-0 p-4 rounded-30" style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
        {metrics.map((company, index) => (
          <Card className="my-2" >
            <div className="px-3 py-2 d-flex justify-content-between">
              <div>
                <h3 className="mt-2">{company.name}</h3>
              </div>
              <div>
                <Button style={{ cursor: "pointer" }} onClick={() => openModal(index)}>Export</Button>
              </div>
            </div>
          </Card>
        ))}
      </Container>
    </div>
  );
};

export default Reports;
