const PERMISSIONS = {
    view_approval_req: 'can_view_approval_req',
    approve_req: 'can_approve_req',
    reject_req: 'can_reject_req',
    revert_req: 'can_revert_req',

    view_users: 'can_view_users',
    add_user: 'can_add_user',
    edit_user: 'can_edit_user',
    delete_user: 'can_delete_user',

    view_firms: 'can_view_firms',
    add_firm: 'can_add_firm',
    edit_firm: 'can_edit_firm',
    delete_firm: 'can_delete_firm',
    
    review_audit_req:'can_review_audit_req',
    approve_audit_req:'can_approve_audit_req',
    reject_audit_req:'can_reject_audit_req',

    view_reports:'can_view_reports',
}

export default PERMISSIONS;