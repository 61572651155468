// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
const appId = process.env.REACT_APP_FIREBASE_APP_ID
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logThisEvent = (event) => {
    // if (process.env.REACT_APP_NODE_ENV !== "development") {
        logEvent(analytics, event)
    // }
}

export const events = {
    // Actions
    disclosure_added: "disclosure_added",
    disclosure_deleted: "disclosure_deleted",

    people_added: "people_added",
    people_deleted: "people_deleted",
    people_edited: "people_edited",	

    contact_form_submitted: "contact_form_submitted",

    dex_swap: "dex_swap",
    dex_liquidity_added: "dex_liquidity_added",

    campaign_registration_step_form_submitted: "campaign_registration_step_form_submitted",
    campaign_registration_step_form_no: "campaign_registration_step_form_no",
    campaign_launched:"campaign_launched",

    campaign_invested: "campaign_invested",
    investment_refunded: "investment_refunded",
    company_tokens_claimed: "company_tokens_claimed",
    busd_withdrawn:"busd_withdrawn",

    proposal_added: "proposal_added",
    vote_on_proposal: "vote_on_proposal",

    log_in:"log_in",
    signup_step_form_no:"signup_step_form_no",//
    signup_step_form_submitted:"signup_step_form_submitted",
    email_verified:"email_verified",
    kyc_completed:"kyc_completed",

    campaign_token_launched:"campaign_token_launched",
    campaign_token_supply_increased:"campaign_token_supply_increased",
    campaign_fundraising_failed:"campaign_fundraising_failed",
    
    company_approved:"company_approved",
    company_rejected:"company_rejected",
    campaign_approved:"campaign_approved",
    campaign_rejected:"campaign_rejected",

    disclosure_approved_by_auditor:"disclosure_approved_by_auditor",
    disclosure_rejected_by_auditor:"disclosure_rejected_by_auditor",
    disclosure_approved_by_admin:"disclosure_approved_by_admin",
    disclosure_rejected_by_admin:"disclosure_rejected_by_admin",
    disclosure_reverted_by_admin:"disclosure_reverted_by_admin",

    // Page Visits
    visited_home_page: "visited_home_page",
    visited_investments_main_page: "visited_investments_main_page",
    visited_campaign_details_page: "visited_campaign_details_page",
    visited_disclosures_main_page: "visited_disclosures_main_page",
    visited_disclosures_details_page: "visited_disclosures_details_page",
    visited_dex_page: "visited_dex_page",
    visited_proposals_main_page: "visited_proposals_main_page",
    visited_profile_page: "visited_profile_page",
    visited_company_registration_page: "visited_company_registration_page",
    visited_log_in_page: "visited_log_in_page",
    visited_signup_page: "visited_signup_page",
}

export default analytics;