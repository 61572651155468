import axios from 'axios'

class AMLRequestServicesClass {

    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('itoken')
        };
    }

    getCompanyVerificationRequests = (filter) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/company_requests'

                axios.get(API_PATH,
                    {
                        params: filter,
                        headers: this.headers,
                    }
                ).then((data) => {
                    if (data.data.status === 200) {
                        resolve(data.data.requests)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    getCompanyVerificationConflictsRequests = (filter) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/company_conflict_requests'

                axios.get(API_PATH,
                    {
                        params: filter,
                        headers: this.headers,
                    }
                ).then((data) => {
                    if (data.data.status === 200) {
                        resolve(data.data.requests)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    getCampaignVerificationRequests = (filter) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/campaign_requests'

                axios.get(API_PATH,
                    {
                        params: filter,
                        headers: this.headers,
                    }
                ).then((data) => {
                    if (data.data.status === 200) {
                        resolve(data.data.requests)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    getCampaignVerificationConflictsRequests = (filter) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/campaign_conflict_requests'

                axios.get(API_PATH,
                    {
                        params: filter,
                        headers: this.headers,
                    }
                ).then((data) => {
                    if (data.data.status === 200) {
                        resolve(data.data.requests)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    getAuditLogs = (id) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/audit_logs'

                axios.get(API_PATH,
                    {
                        params: { id: id, type: 'aml_request' },
                        headers: this.headers,
                    }
                ).then((data) => {
                    if (data.data.status === 200) {
                        resolve(data.data.audit_logs)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    companyRequestApproved = async (id) => {
        return new Promise((resolve, reject) => {
            try {
                const API_PATH = `${process.env.REACT_APP_API_URL}/api/company_request_approved`

                axios.post(API_PATH,
                    {
                        id
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    companyRequestRejected = async (id, reason) => {
        return new Promise((resolve, reject) => {
            try {
                const API_PATH = `${process.env.REACT_APP_API_URL}/api/company_request_rejected`

                axios.post(API_PATH,
                    {
                        id,
                        reason
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    companyRequestConflictResolved = async (id, action, reason) => {
        return new Promise((resolve, reject) => {
            try {
                const API_PATH = `${process.env.REACT_APP_API_URL}/api/company_request_resolved`

                axios.post(API_PATH,
                    {
                        id,
                        action,
                        reason
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log("error from server", { e })
                        resolve(e.response?.data)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    campaignRequestApproved = async (id) => {
        return new Promise((resolve, reject) => {
            try {
                const API_PATH = `${process.env.REACT_APP_API_URL}/api/campaign_request_approved`

                axios.post(API_PATH,
                    {
                        id
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    campaignRequestRejected = async (id, reason) => {
        return new Promise((resolve, reject) => {
            try {
                const API_PATH = `${process.env.REACT_APP_API_URL}/api/campaign_request_rejected`

                axios.post(API_PATH,
                    {
                        id,
                        reason
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log({ e })
                        resolve(false)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }

    campaignRequestConflictResolved = async (id, action, reason) => {
        return new Promise((resolve, reject) => {
            try {
                const API_PATH = `${process.env.REACT_APP_API_URL}/api/campaign_request_resolved`

                axios.post(API_PATH,
                    {
                        id,
                        action,
                        reason
                    },
                    {
                        headers: this.headers
                    }).then((data) => {
                        resolve(data.data)
                    }).catch((e) => {
                        console.log("error from server", { e })
                        resolve(e.response?.data)
                    })

            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
}

const AMLRequestServices = new AMLRequestServicesClass;

export default AMLRequestServices;
