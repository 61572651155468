
/*eslint-disable*/
import React from "react";
import { ConfigContext } from "../../Context"

// reactstrap components
import { Container, Row } from "reactstrap";

const { version } = require('../../../package.json');

const Login = () => {

  const { config } = React.useContext(ConfigContext);

  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center text-center justify-content-center">
            <div className="copyright text-center text-xl-left text-muted text-white">
              © {new Date().getFullYear()}{" "}| {version && version + " |"}
              <a
                className="font-weight-bold ml-1 text-white"
                href={config.footer?.link}
                target="_blank"
              >
                {config.footer?.text}
              </a>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
